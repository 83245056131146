<template>
  <div class="main-layout">
    <CNavbar />
    <v-main class="main-layout__content">
      <div class="main-layout__content-wrapper">
        <router-view />
      </div>
    </v-main>
    <!--    <CFooter/>-->
    <!-- TODO <CSnackbar v-model="showSnackbar" :content="snackbarContent" /> -->
    <CWaitor :show="waitor.show" :message="waitor.message" />
  </div>
</template>

<script>
import CNavbar from '@/views/components/CNavbar'
// import CFooter from "@/views/components/CFooter";
import CWaitor from '@/views/components/CWaitor'
import { mapState } from 'vuex'
import { WAITOR_HIDE } from '@/store/waitor'

export default {
  name: 'MainLayout',

  components: {
    // CSnackbar,
    CNavbar,
    //CFooter,
    CWaitor,
  },

  computed: {
    ...mapState({
      waitor: state => state.waitor,
    }),
  },

  created() {
    this.$store.dispatch(WAITOR_HIDE)
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/vuetify/variables.scss';

.main-layout__content {
  min-height: 100vh;
  min-width: 100vw;
}

.main-layout__content-wrapper {
  background: $color-white;
  box-shadow: 2px 5px 11px rgba(194, 192, 229, 0.7);
  border-radius: $border-radius-root;
  margin: 16px 28px 16px 24px;
  padding: 16px 16px;
}
</style>
