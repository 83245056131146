import apiCall from '@/utils/api'
import httpCodeToErr from '@/utils/http'

export const TARGETING_PARAMETERS_LIST_REQUEST = 'TARGETING_PARAMETERS_LIST_REQUEST'
export const TARGETING_PARAMETERS_DEFAULT_REQUEST = 'TARGETING_PARAMETERS_DEFAULT_REQUEST'
export const TARGETING_PARAMETERS_SAVE_PARAMS_REQUEST = 'TARGETING_PARAMETERS_SAVE_PARAMS_REQUEST'
export const TARGETING_PARAMETERS_UPDATE_PARAMS_REQUEST = 'TARGETING_PARAMETERS_UPDATE_PARAMS_REQUEST'
export const TARGETING_PARAMETERS_MAKE_DEFAULT_REQUEST = 'TARGETING_PARAMETERS_MAKE_DEFAULT_REQUEST'
export const TARGET_FIELDS_GET_REQUESTS = 'TARGET_FIELDS_GET_REQUESTS'

const SET_TARGET_FIELDS = 'SET_TARGET_FIELDS'

const state = {
  status: '',
  targetFields: [],
}

const getters = {}

const actions = {
  [TARGETING_PARAMETERS_LIST_REQUEST]() {
    return new Promise((resolve, reject) => {
      apiCall({ url: `/api/v1/service/target-params`, method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [TARGETING_PARAMETERS_DEFAULT_REQUEST]() {
    return new Promise((resolve, reject) => {
      apiCall({ url: `/api/v1/service/target-params?defonly=t`, method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [TARGETING_PARAMETERS_SAVE_PARAMS_REQUEST](ctx, data) {
    let body = {
      id: data.id,
      title: data.title,
      params: data.params,
    }

    return new Promise((resolve, reject) => {
      apiCall({ url: `/api/v1/service/target-params`, data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [TARGETING_PARAMETERS_UPDATE_PARAMS_REQUEST](ctx, data) {
    let body = {
      id: data.id,
      title: data.title,
      params: data.params,
    }

    return new Promise((resolve, reject) => {
      apiCall({ url: `/api/v1/service/target-params`, data: body, method: 'PUT' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [TARGETING_PARAMETERS_MAKE_DEFAULT_REQUEST](ctx, data) {
    let body = {
      id: data.id,
      default: true,
    }

    return new Promise((resolve, reject) => {
      apiCall({ url: `/api/v1/service/target-params`, data: body, method: 'PUT' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },
  async [TARGET_FIELDS_GET_REQUESTS](ctx) {
    try {
      const { data } = await apiCall({ url: `/api/v1/service/target-fields`, method: 'GET' })
      const responseData = data.map(item => {
        item.question = JSON.parse(item.question)
        if (item.question.answers && item.question.answers.length) {
          item.question.answers.forEach((answer, index) => {
            answer.id = index
          })
        }
        return item
      })
      ctx.commit(SET_TARGET_FIELDS, responseData)
      return responseData
    } catch (err) {
      console.log(err)
      throw httpCodeToErr(err.response.status)
    }
  },
}

const mutations = {
  [SET_TARGET_FIELDS](state, data) {
    state.targetFields = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
