<template>
  <div class="app-header__user">
    <v-menu bottom offset-y>
      <template #activator="{ on }">
        <v-row no-gutters align="center" class="flex-grow-0 flex-nowrap" v-on="on">
          <v-list-item link :style="{ width: 'min-content' }">
            <v-list-item-content>
              <v-list-item-title>{{ user.fullname }}</v-list-item-title>
              <v-list-item-subtitle>{{ userRole }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
              <v-img contain :src="require('@/assets/images/firm-icons/icon-user.png')" max-width="40" alt="" />
            </v-list-item-icon>
          </v-list-item>
        </v-row>
      </template>
      <v-list min-width="220">
        <template #default>
          <v-list-item link @click="goToProfile">
            <v-list-item-content>
              <v-list-item-title>{{ $t('links.profile') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="secondary">mdi-account-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item link @click="logoutClicked">
            <v-list-item-content>
              <v-list-item-title>{{ $t('buttons.logout') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="secondary">mdi-logout</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { USER_IS_AUTHENTICATED, USER_LOGOUT } from '@/store/user'
import { ROUTER_LOGIN, ROUTER_PROFILE } from '@/router/consts'
import routeErrorHandlerMixin from '@/mixins/RouteErrorHandlerMixin'

export default {
  name: 'CNavbarProfile',
  mixins: [routeErrorHandlerMixin],
  computed: {
    ...mapGetters({
      isAuthenticated: USER_IS_AUTHENTICATED,
    }),
    ...mapState({
      user: state => state.user?.userinfo || '',
    }),
    userRole() {
      return this.user?.role?.description || ''
    },
  },

  methods: {
    logoutClicked() {
      this.$store.dispatch(USER_LOGOUT)
      this.$router.push({ name: ROUTER_LOGIN }).catch(this.routerErrorHandler)
    },
    goToProfile() {
      this.$router.push({ name: ROUTER_PROFILE }).catch(this.routerErrorHandler)
    },
  },
}
</script>
