
import Vue from 'vue'

interface ConfirmationPayload {
  title: string
  content: string

  onCancel(): void

  onConfirm(): void
}

export default Vue.extend({
  name: 'AppConfirmation',
  computed: {
    visible() {
      return this.$store.getters['confirmation/visible']
    },
    payload(): ConfirmationPayload {
      return this.$store.getters['confirmation/confirmation']
    },
    title() {
      // @ts-ignore ts vue bug
      return this.payload.title || this.$t('app-confirmation.title')
    },
    content() {
      // @ts-ignore  ts vue bug
      return this.payload.content || this.$t('app-confirmation.content')
    },
  },
  methods: {
    close() {
      this.$store.dispatch('confirmation/closeConfirmation')
    },
    cancel() {
      this.close()
      if (typeof this.payload.onCancel === 'function') {
        this.payload.onCancel()
      }
    },
    confirm() {
      if (typeof this.payload.onConfirm === 'function') {
        this.payload.onConfirm()
      }
      this.close()
    },
  },
})
