import Vue from 'vue'
import App from '@/App.vue'
// @ts-ignore
import router from '@/router/router.js'
// @ts-ignore
import vuetify from '@/plugins/vuetify.js'
// @ts-ignore
import store from '@/store'
import UUID from 'vue-uuid'
import '@/plugins/toastification'
import '@/plugins/apexcharts'
import '@/assets/styles/main.scss'
import AsyncComputed from 'vue-async-computed'

// @ts-ignore
import i18n, { setupAndGetI18n } from './i18n/i18n.js'

Vue.config.productionTip = false
Vue.use(AsyncComputed)
console.log('VUE_APP_BASE_URL: ', process.env.VUE_APP_BASE_URL)

new Vue({
  // @ts-ignore
  store,
  // @ts-ignore
  i18n,
  router,
  // @ts-ignore
  vuetify,
  // @ts-ignore
  UUID,

  methods: {
    setLanguage(language: string) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this
      localStorage.setItem('language', language)
      document.documentElement.lang = language
      // @ts-ignore
      vm.$i18n.locale = language
      // @ts-ignore
      vm.$vuetify.lang.current = language
    },
  },

  created() {
    setupAndGetI18n()
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    // @ts-ignore
    vm.setLanguage('ru')
  },

  render: h => h(App),
}).$mount('#app')
