import {
  ROUTER_HOME,
  ROUTER_PROFILE,
  ROUTER_LOGIN,
  ROUTER_LOGOUT,
  ROUTER_MODERATORS,
  ROUTER_RESEARCHERS,
  ROUTER_ISSUERS,
  ROUTER_INVITATIONS,
  ROUTER_PASSWORD_RESET,
  ROUTER_CALCULATOR,
  ROUTER_PRICE_POLICY,
  ROUTER_TARIFFS,
  ROUTER_PROJECTS,
  ROUTER_PROJECT_STATS,
  ROUTER_PROJECT,
  ROUTER_HISTORY,
  ROUTER_GAMES,
  ROUTER_TARGETING_PARAMS,
  ROUTER_STATISTICS_FOR_VK,
  ROUTER_REPORTS,
  ROUTER_REPORT,
} from './consts'

import AuthLayout from '@/layouts/AuthLayout'

const routes = [
  {
    path: '/',
    name: ROUTER_HOME,
    component: () => import('@/views/pages/Profile.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/login',
    name: ROUTER_LOGIN,
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: AuthLayout,
    },
  },
  {
    path: '/logout',
    name: ROUTER_LOGOUT,
    // TODO component: () => import('@/views/pages/Logout.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/profile',
    name: ROUTER_PROFILE,
    component: () => import('@/views/pages/Profile.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/moderators',
    name: ROUTER_MODERATORS,
    component: () => import('@/views/pages/ModeratorsPage.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/researches',
    name: ROUTER_RESEARCHERS,
    component: () => import('@/views/pages/ResearchersPage.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/issuers',
    name: ROUTER_ISSUERS,
    component: () => import('@/views/pages/IssuersPage.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/invitations',
    name: ROUTER_INVITATIONS,
    component: () => import('@/views/pages/Invitations.vue'),
  },
  {
    path: '/password/reset',
    name: ROUTER_PASSWORD_RESET,
    component: () => import('@/views/pages/PasswordReset.vue'),
  },
  {
    path: '/calculator',
    name: ROUTER_CALCULATOR,
    component: () => import('@/views/pages/Calculator.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/price-policy',
    name: ROUTER_PRICE_POLICY,
    component: () => import('@/views/pages/PricePolicy.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/tariffs',
    name: ROUTER_TARIFFS,
    component: () => import('@/views/pages/Tariffs.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/projects',
    name: ROUTER_PROJECTS,
    component: () => import('@/views/pages/Projects.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/reports',
    name: ROUTER_REPORTS,
    component: () => import('@/views/pages/Reports.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/project',
    name: ROUTER_PROJECT,
    component: () => import('@/views/pages/ProjectPage.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/report',
    name: ROUTER_REPORT,
    component: () => import('@/views/pages/Report.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/project/stats',
    name: ROUTER_PROJECT_STATS,
    component: () => import('@/views/pages/ProjectStats.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/history',
    name: ROUTER_HISTORY,
    component: () => import('@/views/pages/History.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/games',
    name: ROUTER_GAMES,
    component: () => import('@/views/pages/GamesPage.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/targeting-parameters',
    name: ROUTER_TARGETING_PARAMS,
    component: () => import('@/views/pages/TargetingParameters.vue'),
    meta: { authorizationRequired: true },
  },
  {
    path: '/statistics',
    name: ROUTER_STATISTICS_FOR_VK,
    component: () => import('@/views/pages/StatisticsForVk.vue'),
    meta: { authorizationRequired: true },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

export default routes
