<template>
  <Logo />
</template>

<script>
import Logo from '@/assets/images/logo-full.svg'

export default {
  name: 'CNavbarLogo',
  components: {
    Logo,
  },
}
</script>
