export const GET_STATISTIC_VK = 'GET_STATISTIC_VK'
import apiCall from '@/utils/api'
const actions = {
  async [GET_STATISTIC_VK]() {
    const response = await apiCall({ url: '/api/v1/service/vk-stats', method: 'GET' })
    console.log(response)
    return response.data
  },
}

export default {
  actions,
}
