import apiCall from '@/utils/api'
import httpCodeToErr from '@/utils/http'

export const HISTORY_LIST_REQUEST = 'HISTORY_LIST_REQUEST'

const state = {
  status: '',
}

const getters = {}

const actions = {
  [HISTORY_LIST_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      apiCall({ url: `/api/v1/service/history?upto=${data.timestamp}&limit=${data.limit}`, method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
