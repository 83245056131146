<template>
  <div class="auth-layout" :style="{ 'background-image': 'url(' + require('@/assets/images/background-1.png') + ')' }">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
}
</script>

<style scoped>
.auth-layout {
  min-height: 100vh;
  min-width: 100vw;
  background-position: center right;
  background-size: cover;
}
</style>
