export const ROUTER_HOME = 'Home'
export const ROUTER_PROFILE = 'Profile'
export const ROUTER_LOGIN = 'Login'
export const ROUTER_LOGOUT = 'Logout'
export const ROUTER_MODERATORS = 'Moderators'
export const ROUTER_RESEARCHERS = 'ResearchersPage'
export const ROUTER_ISSUERS = 'Issuers'
export const ROUTER_INVITATIONS = 'Invitations'
export const ROUTER_PASSWORD_RESET = 'PasswordReset'
export const ROUTER_CALCULATOR = 'Calculator'
export const ROUTER_PRICE_POLICY = 'PricePolicy'
export const ROUTER_TARIFFS = 'Tariffs'
export const ROUTER_PROJECTS = 'Projects'
export const ROUTER_PROJECT = 'Project'
export const ROUTER_PROJECT_STATS = 'ProjectStats'
export const ROUTER_HISTORY = 'History'
export const ROUTER_GAMES = 'GamesPage'
export const ROUTER_TARGETING_PARAMS = 'TargetingParameters'
export const ROUTER_STATISTICS_FOR_VK = 'StatisticsForVk'
export const ROUTER_REPORTS = 'Reports'
export const ROUTER_REPORT = 'Report'
