const state = () => {
  return {
    visible: false,
    confirmation: {
      title: '',
      content: '',
      onConfirm: () => {},
      onCancel: () => {},
    },
  }
}
const mutations = {
  setVisibility(state, value) {
    state.visible = value
  },
  setConfirmation(state, confirmation) {
    state.confirmation = confirmation
  },
}
const actions = {
  userConfirm({ commit }, confirmation) {
    commit('setConfirmation', confirmation)
    commit('setVisibility', true)
  },
  closeConfirmation({ commit }) {
    commit('setVisibility', false)
  },
}
const getters = {
  visible: state => state.visible,
  confirmation: state => state.confirmation,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
