<template>
  <div>
    <v-app-bar class="app-header" app flat light clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mr-3">
        <v-icon color="secondary">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <CNavbarLogo />
      <v-spacer></v-spacer>
      <CNavbarProfile />
    </v-app-bar>

    <v-navigation-drawer
      v-if="toShow"
      app
      clipped
      v-model="drawer"
      width="300"
      class="app-side-menu"
      :style="{ 'background-image': 'url(' + require('@/assets/images/background-2-800.png') + ')' }"
    >
      <v-list tile class="app-side-menu__list">
        <div v-for="(item, idx) in navigationItems" :key="`navItem${idx}`">
          <div v-if="showForRoles(item.forRoles)">
            <v-list-item
              link
              v-if="!item.children || item.children.length === 0"
              :key="item.title"
              :to="item.link"
              :href="item.href"
            >
              <v-list-item-icon class="list-item__icon">
                <v-img contain :src="item.icon" max-width="30" alt="" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group v-else>
              <template v-slot:activator>
                <v-list-item-icon class="list-item__icon">
                  <v-img contain :src="item.icon" max-width="30" alt="" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="(child, idx) in item.children">
                <v-list-item
                  v-if="!doNotShowForRoles(child.deniedForRoles)"
                  :key="`navItemChild${idx}`"
                  :to="child.link"
                  link
                >
                  <v-list-item-icon />
                  <v-list-item-content>
                    <v-list-item-title>{{ child.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import CNavbarLogo from '@/views/components/CNavbarLogo'
import CNavbarProfile from '@/views/components/CNavbarProfile'
import { mapGetters, mapState } from 'vuex'
import {
  USER_IS_AUTHENTICATED,
  USER_ROLE_STR_ADMINISTRATOR,
  USER_ROLE_STR_MODERATOR_VK,
  USER_ROLE_STR_RESEARCHER,
  USER_ROLE_STR_ISSUER,
  USER_ROLE_STR_MODERATOR,
} from '@/store/user'
import {
  ROUTER_ISSUERS,
  ROUTER_MODERATORS,
  ROUTER_RESEARCHERS,
  ROUTER_CALCULATOR,
  ROUTER_PRICE_POLICY,
  ROUTER_TARIFFS,
  ROUTER_PROJECTS,
  ROUTER_HISTORY,
  ROUTER_GAMES,
  ROUTER_TARGETING_PARAMS,
  ROUTER_REPORTS,
} from '@/router/consts'

export default {
  name: 'CNavbar',
  components: {
    CNavbarLogo,
    CNavbarProfile,
  },

  computed: {
    ...mapGetters({
      isAuthenticated: USER_IS_AUTHENTICATED,
    }),
    ...mapState({
      role: state => state.user?.userinfo?.role.name,
    }),
    toShow() {
      return this.isAuthenticated // TODO && this.routerpath != "/" && this.routepath != "/"
    },
  },

  data() {
    return {
      drawer: true,
      navigationItems: {
        accessControl: {
          icon: require('@/assets/images/firm-icons/icon-users.png'),
          title: 'Управление доступом', // TODO i18n
          forRoles: [USER_ROLE_STR_ADMINISTRATOR],
          children: {
            researchers: {
              title: this.$t('pages.researchers.title'),
              link: { name: ROUTER_RESEARCHERS },
            },
            issuers: {
              title: this.$t('pages.issuers.title'),
              link: { name: ROUTER_ISSUERS },
            },
            games: {
              title: this.$t('pages.games.title'),
              link: { name: ROUTER_GAMES },
            },
            moderators: {
              title: this.$t('pages.moderators.title'),
              link: { name: ROUTER_MODERATORS },
            },
          },
        },
        games: {
          icon: 'mdi-gamepad-variant-outline',
          title: this.$t('pages.games.title'),
          link: { name: ROUTER_GAMES },
          forRoles: [USER_ROLE_STR_ISSUER],
        },
        prices: {
          icon: require('@/assets/images/firm-icons/icon-cash.png'),
          title: this.$t('links.pricing'),
          forRoles: [USER_ROLE_STR_ADMINISTRATOR],
          children: {
            calculator: {
              title: this.$t('pages.calculator.title'),
              link: { name: ROUTER_CALCULATOR },
            },
            tariffs: {
              title: this.$t('pages.tariffs.title'),
              link: { name: ROUTER_TARIFFS },
            },
            pricePolicy: {
              title: this.$t('pages.pricePolicy.title'),
              link: { name: ROUTER_PRICE_POLICY },
            },
          },
        },
        targetingParams: {
          icon: require('@/assets/images/firm-icons/icon-flash.png'),
          title: this.$t('links.target-params'),
          link: { name: ROUTER_TARGETING_PARAMS },
          forRoles: [USER_ROLE_STR_ADMINISTRATOR],
        },
        analytics: {
          icon: require('@/assets/images/firm-icons/icon-monitoring.png'),
          title: this.$t('links.analytics'),
          href: 'https://datalens.yandex.ru/nqv56j79blmwe-dashbord',
          forRoles: [USER_ROLE_STR_ADMINISTRATOR],
        },
        projects: {
          icon: require('@/assets/images/firm-icons/icon-projects.png'),
          title: this.$t('pages.projects.title'),
          forRoles: [
            USER_ROLE_STR_ADMINISTRATOR,
            USER_ROLE_STR_RESEARCHER,
            USER_ROLE_STR_MODERATOR_VK,
            USER_ROLE_STR_MODERATOR,
          ],
          children: {
            projects: {
              title: this.$t('pages.questionnaire.title'),
              link: { name: ROUTER_PROJECTS },
            },
            reports: {
              title: this.$t('pages.reports.title'),
              link: { name: ROUTER_REPORTS },
              deniedForRoles: [USER_ROLE_STR_MODERATOR_VK, USER_ROLE_STR_MODERATOR],
            },
          },
        },
        logs: {
          icon: require('@/assets/images/firm-icons/icon-logs.png'),
          title: this.$t('links.logs'),
          link: { name: ROUTER_HISTORY },
          forRoles: [USER_ROLE_STR_ADMINISTRATOR],
        },
        statistics: {
          icon: require('@/assets/images/firm-icons/icon-stats.png'),
          title: this.$t('links.stats'),
          href: 'https://datalens.yandex.ru/wf2u1ikahjlen-vk',
          forRoles: [USER_ROLE_STR_MODERATOR_VK, USER_ROLE_STR_ADMINISTRATOR], // TODO remove USER_ROLE_STR_ADMINISTRATOR when api will done
        },
      },
    }
  },

  methods: {
    showForRoles(rolesList) {
      if (rolesList === undefined) return false
      return rolesList.find(el => el === this.role) === this.role || false
    },
    doNotShowForRoles(rolesList) {
      if (rolesList === undefined) return false
      return rolesList.includes(this.role)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vuetify/variables.scss';

.app-header {
  box-shadow: 2px 5px 11px rgba(194, 192, 229, 0.7) !important;
}

.app-side-menu {
  border-radius: $border-radius-root;
  margin: 16px 0 16px 8px;
  box-shadow: 2px 5px 11px rgba(194, 192, 229, 0.7);
  background-position: right;
  background-size: cover;
}

.app-side-menu__list {
  background-color: $color-white !important;
  border-radius: 0 0 $border-radius-root $border-radius-root !important;
}

.list-item__icon {
  margin-right: 16px !important;
}
</style>
