import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/lib/locale/ru'
import en from 'vuetify/lib/locale/en'
import colors from '@/assets/styles/vuetify/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
    font: {
      family: 'Inter',
    },
    themes: {
      light: colors,
    },
  },
  lang: {
    locales: { ru, en },
    current: 'ru',
  },
})
