import apiCall from '@/utils/api'
import httpCodeToErr from '@/utils/http'

export const GAMES_LIST_REQUEST = 'GAMES_LIST_REQUEST'
export const GAMES_GENRES_LIST_REQUEST = 'GAMES_GENRES_LIST_REQUEST'
export const GAMES_PLATFORMS_LIST_REQUEST = 'GAMES_PLATFORMS_LIST_REQUEST'
export const GAMES_CURRENCIES_LIST_REQUEST = 'GAMES_CURRENCIES_LIST_REQUEST'
export const GAMES_ADD_GAME_REQUEST = 'GAMES_ADD_GAME_REQUEST'
export const GAMES_UPDATE_GAME_REQUEST = 'GAMES_UPDATE_GAME_REQUEST'
export const GAMES_DELETE_GAME_REQUEST = 'GAMES_DELETE_GAME_REQUEST'
export const GAMES_ADD_GENRE_REQUEST = 'GAMES_ADD_GENRE_REQUEST'
export const GAMES_ADD_PLATFORM_REQUEST = 'GAMES_ADD_PLATFORM_REQUEST'
export const GAMES_ADD_CURRENCY_REQUEST = 'GAMES_ADD_CURRENCY_REQUEST'
export const GAMES_CREATE_TOKEN_REQUEST = 'GAMES_CREATE_TOKEN_REQUEST'
export const GAMES_CHANGE_STATUS_REQUEST = 'GAMES_CHANGE_STATUS_REQUEST'

export const GAMES_STATUSES = new Map([
  [0, '-'], // TODO i18n
  [1, 'Ожидает подключения SDK'], // TODO i18n
  [2, 'SDK подключено'], // TODO i18n
  [3, 'На модерации'], // TODO i18n
  [4, 'Игра подключена'], // TODO i18n
  [5, 'Игра отключена'], // TODO i18n
])

const state = {
  status: '',
}

const getters = {}

const actions = {
  [GAMES_LIST_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      apiCall({ url: `/api/v1/service/games?oid=${data.orgId}`, method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [GAMES_GENRES_LIST_REQUEST]() {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/games/genres', method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [GAMES_PLATFORMS_LIST_REQUEST]() {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/games/platforms', method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [GAMES_CURRENCIES_LIST_REQUEST]() {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/games/currencies', method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [GAMES_ADD_GENRE_REQUEST](ctx, data) {
    let body = {
      name: data.name,
    }

    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/games/genres', data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
        .finally(() => {})
    })
  },

  [GAMES_ADD_PLATFORM_REQUEST](ctx, data) {
    let body = {
      name: data.name,
    }

    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/games/platforms', data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
        .finally(() => {})
    })
  },

  [GAMES_ADD_CURRENCY_REQUEST](ctx, data) {
    let body = {
      name: data.name,
    }

    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/games/currencies', data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
        .finally(() => {})
    })
  },

  [GAMES_ADD_GAME_REQUEST](ctx, data) {
    let body = {
      name: data.name,
      url: data.url,
      reward: data.reward,
      orgId: data.orgId,
      userId: data.userId,
      genreId: data.genreId,
      platformId: data.platformId,
      currencyId: data.currencyId,
    }

    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/games', data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
        .finally(() => {})
    })
  },
  [GAMES_UPDATE_GAME_REQUEST](ctx, data) {
    let body = {
      gameId: data.id,
      name: data.name,
      genreId: data.genreId,
      platformId: data.platformId,
      statusId: data.statusId,
    }

    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/game', data: body, method: 'PUT' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response?.status))
        })
        .finally(() => {})
    })
  },

  [GAMES_DELETE_GAME_REQUEST](ctx, data) {
    let body = {
      gameId: data.gameId,
    }

    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/game', data: body, method: 'DELETE' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response?.status))
        })
        .finally(() => {})
    })
  },

  [GAMES_CREATE_TOKEN_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      let body = {
        gameId: data.gameId,
        // sdkId: data.sdkId,
        // sdkKey: data.sdkKey,
      }

      apiCall({ url: '/api/v1/service/games/sdk', data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [GAMES_CHANGE_STATUS_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      let body = {
        gameId: data.gameId,
        statusId: data.statusId,
      }

      apiCall({ url: `/api/v1/service/games`, data: body, method: 'PUT' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
