export const AUTH_TOKEN_KEY = 'x-auth-token'
export const REFRESH_TOKEN_KEY = 'x-refresh-token'

export const CONST_ID_LENGTH = 64

// WARNING: Keep in sync with survey-api-srv internal/common/consts.go
export const QuestionCategoryEnum = {
  QUESTION_CATEGORY_ONE: 1,
  QUESTION_CATEGORY_FEW: 2,
  QUESTION_CATEGORY_SCREENING: 3,
  QUESTION_CATEGORY_QUALIFICATION: 4,
  QUESTION_CATEGORY_OPEN: 5,
  QUESTION_CATEGORY_FROM_TO: 6,
  QUESTION_CATEGORY_REGION: 7,
  QUESTION_CATEGORY_CITY: 8,
}

export const TargetPlatformEnum = {
  ALL: 1,
  OTHERS: 2,
  VK: 3,
}
/**
 * const (
  CategoryUnknown       CategoryType = 0 // Неизвестный тип.
  CategoryOne           CategoryType = 1 // Только 1 вариант ответа.
  CategoryFew           CategoryType = 2 // Несколько вариантов ответа.
  CategoryScreening     CategoryType = 3 // Скрининг вопрос.
  CategoryQualification CategoryType = 4 // Квалификационный вопрос.
  CategoryOpen          CategoryType = 5 // Открытый вопрос.
  CategoryIntValueRange CategoryType = 6 // Диапазон числовых значений.
  CategoryAutoRegion    CategoryType = 7 // Вопрос из "автоматического поля региона".
  CategoryAutoCity      CategoryType = 8 // Вопрос из "автоматического поля города".
)
 */
export const CategoryList = [
  //TODO i18n
  { key: 1, id: QuestionCategoryEnum.QUESTION_CATEGORY_ONE, name: '1 вариант ответа' },
  { key: 2, id: QuestionCategoryEnum.QUESTION_CATEGORY_FEW, name: 'Несколько вариантов ответа' },
  { key: 3, id: QuestionCategoryEnum.QUESTION_CATEGORY_SCREENING, name: 'Скрининговый вопрос' },
  { key: 4, id: QuestionCategoryEnum.QUESTION_CATEGORY_QUALIFICATION, name: 'Квалификационный вопрос' },
  { key: 5, id: QuestionCategoryEnum.QUESTION_CATEGORY_OPEN, name: 'Открытый вопрос' },
  { key: 6, id: QuestionCategoryEnum.QUESTION_CATEGORY_FROM_TO, name: 'Диапазон числовых значений' },
  { key: 7, id: QuestionCategoryEnum.QUESTION_CATEGORY_FROM_TO, name: 'Вопрос со слайдером' },
  { key: 8, id: QuestionCategoryEnum.QUESTION_CATEGORY_FROM_TO, name: 'Вопрос со звездами' },
  { key: 9, id: QuestionCategoryEnum.QUESTION_CATEGORY_FROM_TO, name: 'NPS вопрос' },
  { key: 10, id: QuestionCategoryEnum.QUESTION_CATEGORY_FEW, name: 'Вопрос с ранжированием' },
  { key: 11, id: QuestionCategoryEnum.QUESTION_CATEGORY_ONE, name: 'Матричный вопрос' },
]

export const TargetCategoryList = [
  { id: QuestionCategoryEnum.QUESTION_CATEGORY_ONE, name: '1 вариант ответа' },
  { id: QuestionCategoryEnum.QUESTION_CATEGORY_FEW, name: 'Несколько вариантов ответа' },
  { id: QuestionCategoryEnum.QUESTION_CATEGORY_QUALIFICATION, name: 'Квалификационный вопрос' },
  { id: QuestionCategoryEnum.QUESTION_CATEGORY_FROM_TO, name: 'Диапазон числовых значений' },
  { id: QuestionCategoryEnum.QUESTION_CATEGORY_REGION, name: 'Автовопрос для региона' },
  { id: QuestionCategoryEnum.QUESTION_CATEGORY_CITY, name: 'Автовопрос для города' },
]

// WARNING: Keep in sync with survey-api-srv internal/common/consts.go
export const DEMOGRAPHIC_CATEGORY_ONE_QUESTION = 1
export const DEMOGRAPHIC_CATEGORY_RANGE = 2
export const DEMOGRAPHIC_CATEGORY_REGION = 3
export const DemographicCategoryList = [
  //TODO i18n
  { id: 101, name: '1 вариант ответа' },
  { id: 102, name: 'Диапазон значений' },
  { id: 103, name: 'Регион' },
]

export const REDIRECT_TO_NEW_ANKETA = 1
export const REDIRECT_TO_EXISTING_ANKETA = 2
export const REDIRECT_TO_NEW_BLOCK_IN_CURRENT_ANKETA = 3
export const REDIRECT_TO_EXISTING_BLOCK_IN_CURRENT_ANKETA = 4
export const REDIRECT_TO_NEW_QUESTION_IN_CURRENT_BLOCK = 5
export const REDIRECT_TO_EXISTING_QUESTION_IN_CURRENT_BLOCK = 6
export const REDIRECT_TO_COMPLETE_BLOCK = 7

export const RedirectToList = [
  //TODO i18n
  { id: REDIRECT_TO_NEW_ANKETA, name: 'новая анкета' },
  { id: REDIRECT_TO_EXISTING_ANKETA, name: 'существующая анкета' },
  { id: REDIRECT_TO_NEW_BLOCK_IN_CURRENT_ANKETA, name: 'новый блок в текущей анкете' },
  { id: REDIRECT_TO_EXISTING_BLOCK_IN_CURRENT_ANKETA, name: 'существующий блок в текущей анкете' },
  { id: REDIRECT_TO_NEW_QUESTION_IN_CURRENT_BLOCK, name: 'новый вопрос в текущем блоке' },
  { id: REDIRECT_TO_EXISTING_QUESTION_IN_CURRENT_BLOCK, name: 'существующий вопрос в текущем блоке' },
  { id: REDIRECT_TO_COMPLETE_BLOCK, name: 'завершить текущий блок' },
]

export const ScreeningValueList = [
  { value: 0, name: 'Допустить до следующих вопросов' },
  { value: 1, name: 'Не допустить до следующих вопросов' },
  { value: 2, name: 'Допустить до следующих вопросов только если выбран хотя бы один допускающий ответ' },
]

export const ProjectTypeEnum = {
  TARGET: 2,
  DEFAULT: 1,
}
