import apiCall from '@/utils/api'
import httpCodeToErr from '@/utils/http'

export const GET_CITIES_BY_PREFIX_REQUEST = 'GET_CITIES_BY_PREFIX_REQUEST'
export const GET_CITIES_BY_IDS_REQUEST = 'GET_CITIES_BY_IDS_REQUEST'

const state = {
  status: '',
}

const getters = {}

const actions = {
  [GET_CITIES_BY_PREFIX_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      apiCall({ url: `/api/v1/service/cities?q=${data.prefix}`, method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },
  [GET_CITIES_BY_IDS_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      apiCall({ url: `/api/v1/service/cities?ids=${data.ids}`, method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
