import apiCall from '@/utils/api'
import httpCodeToErr from '@/utils/http'

export const INVITATIONS_CONFIRM = 'INVITATIONS_CONFIRM'

const state = {}

const getters = {}

const actions = {
  [INVITATIONS_CONFIRM](ctx, data) {
    return new Promise((resolve, reject) => {
      let body = {
        token: data.token,
      }

      apiCall({ url: '/api/v1/service/invitations/confirm', data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data.resetToken)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
