import apiCall from '@/utils/api'
import httpCodeToErr from '@/utils/http'

export const ISSUERS_LIST_REQUEST = 'ISSUERS_LIST_REQUEST'

const state = {
  status: '',
}

const getters = {}

const actions = {
  [ISSUERS_LIST_REQUEST]() {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/users?roles=Issuer', method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
