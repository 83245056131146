// import Vue from 'vue'
// import apiCall from "@/utils/api";
// import httpCodeToErr from '@/utils/http';

// export const RESEARCHERS_LIST_REQUEST = 'RESEARCHERS_LIST_REQUEST'
// export const RESEARCHERS_CATEGORIES_LIST_REQUEST = 'RESEARCHERS_CATEGORIES_LIST_REQUEST'
// export const RESEARCHERS_ORGANIZATIONS_LIST_REQUEST = 'RESEARCHERS_ORGANIZATIONS_LIST_REQUEST'
// export const RESEARCHERS_SAVE_USER_REQUEST = 'RESEARCHERS_SAVE_USER_REQUEST'

// export const RESEARCHERS_STATUS_LOADING = 'loading'
// export const RESEARCHERS_STATUS_FINISHED = 'finished'

// const RESEARCHERS_LOADING = 'RESEARCHERS_LOADING'
// const RESEARCHERS_FINISHED = 'RESEARCHERS_FINISHED'

const state = {
  // status: ''
}

const getters = {}

const actions = {
  // [RESEARCHERS_LIST_REQUEST] (ctx) {
  //   return new Promise((resolve, reject) => {
  //     ctx.commit(RESEARCHERS_LOADING);
  //     apiCall({url: '/api/v1/service/users?roles=Researcher', method: 'GET'})
  //       .then(resp => {
  //         resolve(resp.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         reject(httpCodeToErr(err.response.status));
  //       })
  //       .finally(() => {
  //         ctx.commit(RESEARCHERS_FINISHED);
  //       })
  //   })
  // },
  // [RESEARCHERS_CATEGORIES_LIST_REQUEST] (ctx) {
  //   return new Promise((resolve, reject) => {
  //     ctx.commit(RESEARCHERS_LOADING);
  //     apiCall({url: '/api/v1/service/categories', method: 'GET'})
  //       .then(resp => {
  //         resolve(resp.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         reject(httpCodeToErr(err.response.status));
  //       })
  //       .finally(() => {
  //         ctx.commit(RESEARCHERS_FINISHED);
  //       })
  //   })
  // },
  // [RESEARCHERS_ORGANIZATIONS_LIST_REQUEST] (ctx) {
  //   return new Promise((resolve, reject) => {
  //     ctx.commit(RESEARCHERS_LOADING);
  //     apiCall({url: '/api/v1/service/organizations', method: 'GET'})
  //       .then(resp => {
  //         resolve(resp.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         reject(httpCodeToErr(err.response.status));
  //       })
  //       .finally(() => {
  //         ctx.commit(RESEARCHERS_FINISHED);
  //       })
  //   })
  // },
  // [RESEARCHERS_SAVE_USER_REQUEST] (ctx, data) {
  //   let body = {
  //     email: data.user.email,
  //     organization: data.user.organization,
  //     category: data.category,
  //     message: data.message,
  //     roleId:
  //   };
  //   return new Promise((resolve, reject) => {
  //     ctx.commit(RESEARCHERS_LOADING);
  //     apiCall({url: '/api/v1/service/user', data: body, method: 'POST'})
  //       .then(resp => {
  //         resolve(resp.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         reject(httpCodeToErr(err.response.status));
  //       })
  //       .finally(() => {
  //         ctx.commit(RESEARCHERS_FINISHED);
  //       })
  //   })
  // },
}

const mutations = {
  // [RESEARCHERS_LOADING]() {
  //   Vue.set(state, 'status', RESEARCHERS_LOADING);
  // },
  // [RESEARCHERS_FINISHED]() {
  //   Vue.set(state, 'status', RESEARCHERS_FINISHED);
  // },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
