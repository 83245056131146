import Vue from 'vue'

export const WAITOR_SHOW = 'WAITOR_SHOW'
export const WAITOR_HIDE = 'WAITOR_HIDE'

const WAITOR_SET_SHOW = 'WAITOR_SET_SHOW'
const WAITOR_SET_MESSAGE = 'WAITOR_SET_MESSAGE'

const state = {
  show: false,
  message: '',
}

const getters = {}

const actions = {
  [WAITOR_SHOW](ctx, msg) {
    ctx.commit(WAITOR_SET_SHOW, true)

    if (msg && msg.length > 0) {
      ctx.commit(WAITOR_SET_MESSAGE, msg)
    }
  },

  [WAITOR_HIDE](ctx) {
    ctx.commit(WAITOR_SET_SHOW, false)
    ctx.commit(WAITOR_SET_MESSAGE, '')
  },
}

const mutations = {
  [WAITOR_SET_SHOW](state, show) {
    Vue.set(state, 'show', show)
  },

  [WAITOR_SET_MESSAGE](state, message) {
    Vue.set(state, 'message', message)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
