const storage = localStorage
const SETTINGS_STORAGE_ITEM_NAME = 'project-constructor-options'

const state = {
  options: {
    showRelations: false,
  },
}

const savedOptions = JSON.parse(storage.getItem(SETTINGS_STORAGE_ITEM_NAME))
if (savedOptions) {
  state.options = Object.assign({}, state.options, savedOptions)
}

const mutations = {
  setOption(state, { optionName, optionValue }) {
    if (Object.prototype.hasOwnProperty.call(state.options, optionName)) {
      state.options[optionName] = optionValue
      storage.setItem(SETTINGS_STORAGE_ITEM_NAME, JSON.stringify(state.options))
    }
  },
}
const actions = {}
const getters = {
  showRelations: state => state.options.showRelations,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
