export default {
  primary: '#8684CC',
  secondary: '#2E2D66',
  accent: '#C2C0E5',
  error: '#ff8469', // default vuetify
  success: '#4ad295', // default vuetify
  info: '#E9E8FB',
  warning: '#ffb758', // default vuetify
  'primary-light': '#E9E8FB',
  'secondary-light': '#C2C0E5',
}
