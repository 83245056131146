<template>
  <v-app>
    <component :is="layout">
      <slot />
    </component>
    <app-confirmation />
  </v-app>
</template>

<script>
import mainLayout from '@/layouts/MainLayout'
import AppConfirmation from '@/views/components/app/AppConfirmation.vue'

export default {
  name: 'App',
  components: { AppConfirmation },
  computed: {
    layout() {
      return this.$route.meta.layout || mainLayout
    },
  },
}
</script>
