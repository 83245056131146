<template>
  <v-overlay :value="show" z-index="1000">
    <div style="min-width: 500px" class="text-center">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <br v-if="message.length > 0" />
      <br v-if="message.length > 0" />
      <strong v-if="message.length > 0">{{ message }}</strong>
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: 'CWaitor',

  props: {
    show: Boolean,
    message: String,
  },
}
</script>
