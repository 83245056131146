import i18n from '@/i18n/i18n'

export const ERROR_NOT_HANDLED = 'ERROR_NOT_HANDLED'
export const ERROR_UNAUTHORIZED = 'ERROR_UNAUTHORIZED'
export const ERROR_INTERNAL_ERROR = 'ERROR_INTERNAL_ERROR'

const httpCodeToErr = code => {
  switch (code) {
    case 401:
      return { code, msg: i18n.t('http.errors.unauthorized') }

    case 403:
      return { code, msg: i18n.t('http.errors.forbidden') }

    case 413:
      return { code, msg: i18n.t('http.errors.payloadTooLarge') }
    case 409:
      return { code, msg: i18n.t('http.errors.conflict') }

    case 500:
      return { code, msg: i18n.t('http.errors.internalError') }
  }

  return { code, msg: i18n.t('http.errors.unhandledError') }
}

export default httpCodeToErr
