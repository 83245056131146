// import Vue from 'vue'
// import VueI18n from 'vue-i18n'
// import messages from '@/i18n/locales/ru'

// Vue.use(VueI18n);

// export const i18n = new VueI18n({
//   locale: 'ru',
//   fallbackLocale: 'ru',
//   messages,
// })

// const loadedLanguages = ['ru'] // our default language that is preloaded

// function setI18nLanguage (lang) {
//   i18n.locale = lang
//   document.querySelector('html').setAttribute('lang', lang)
//   return lang
// }

// export function loadLanguageAsync(lang) {
//   // If the same language
//   if (i18n.locale === lang) {
//     return Promise.resolve(setI18nLanguage(lang))
//   }

//   // If the language was already loaded
//   if (loadedLanguages.includes(lang)) {
//     return Promise.resolve(setI18nLanguage(lang))
//   }

//   // If the language hasn't been loaded yet
//   return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/locales/${lang}.js`).then(
//     messages => {
//       i18n.setLocaleMessage(lang, messages.default)
//       loadedLanguages.push(lang)
//       return setI18nLanguage(lang)
//     }
//   )
// }

import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  fallbackRoot: false,
  silentTranslationWarn: true,

  missing(locale, key) {
    // TODO
    return key
  },
})

export function setupAndGetI18n() {
  i18n.setLocaleMessage('en', require('@/i18n/locales/en.json'))
  i18n.setLocaleMessage('ru', require('@/i18n/locales/ru.json'))
}

export default i18n
