import apiCall from '@/utils/api'
import httpCodeToErr from '@/utils/http'

export const ORGANIZATIONS_LIST_REQUEST = 'ORGANIZATIONS_LIST_REQUEST'
export const ORGANIZATION_REQUEST = 'ORGANIZATION_REQUEST'
export const ORGANIZATIONS_CATEGORIES_LIST_REQUEST = 'ORGANIZATIONS_CATEGORIES_LIST_REQUEST'
export const ORGANIZATIONS_CREATE_REQUEST = 'ORGANIZATIONS_CREATE_REQUEST'

export const ORGANIZATIONS_STATUS_LOADING = 'loading'
export const ORGANIZATIONS_STATUS_FINISHED = 'finished'

export const ORGANIZATIONS_TYPE_RESEARCHERS = 'Researchers'
export const ORGANIZATIONS_TYPE_ISSUERS = 'Issuers'

const state = {
  status: '',
}

const getters = {}

const actions = {
  [ORGANIZATIONS_LIST_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      let typeStr = ''

      if (data.type) {
        let type = encodeURI(data.type)
        typeStr = `?type=${type}`
      }

      apiCall({ url: `/api/v1/service/organizations${typeStr}`, method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(httpCodeToErr(err.response.status))
        })
        .finally(() => {})
    })
  },

  [ORGANIZATION_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      apiCall({ url: `/api/v1/service/organization?id=${data.id}`, method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
        .finally(() => {})
    })
  },

  [ORGANIZATIONS_CATEGORIES_LIST_REQUEST]() {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/categories', method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
        .finally(() => {})
    })
  },

  [ORGANIZATIONS_CREATE_REQUEST](ctx, data) {
    let body = {
      name: data.name,
      type: data.type,
      category: data.category,
    }

    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/organizations', data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
        .finally(() => {})
    })
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
