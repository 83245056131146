import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import routes from './routes'
import { USER_IS_AUTHENTICATED, USER_RESTORE_TOKENS } from '@/store/user'
import { ROUTER_LOGIN } from '@/router/consts'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch(USER_RESTORE_TOKENS)

  if (to.matched.some(record => record.meta.authorizationRequired) && store.getters[USER_IS_AUTHENTICATED] == false) {
    return next({ name: ROUTER_LOGIN, params: { next: to } })
  }

  next()
})

export default router
