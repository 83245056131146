import axios from 'axios'

const apiCall = ({ url, data, method, responseType = 'application/json; charset=UTF-8' } = {}) => {
  const reqConfig = {
    method: method,
    url: url,
    data: data,
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
      Authorization: 'Bearer ABC=',
      responseType,
      // withCredentials: true,
    },
  }

  return axios.request(reqConfig)
}

export default apiCall
