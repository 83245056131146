// import apiCall from "@/utils/api";
// import httpCodeToErr from '@/utils/http';

// export const MODERATORS_LIST_REQUEST = 'MODERATORS_LIST_REQUEST'
// export const MODERATORS_SAVE_USER_REQUEST = 'MODERATORS_SAVE_USER_REQUEST'

const state = {
  status: '',
}

const getters = {}

const actions = {
  // [MODERATORS_LIST_REQUEST] () {
  //   return new Promise((resolve, reject) => {
  //     apiCall({url: '/api/v1/service/users?roles=Moderator&roles=Moderator%20VK', method: 'GET'})
  //       .then(resp => {
  //         resolve(resp.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         reject(httpCodeToErr(err.response.status));
  //       });
  //   })
  // },
  // [MODERATORS_SAVE_USER_REQUEST] (ctx, data) {
  //   return new Promise((resolve, reject) => {
  //     let body = {
  //       email: data.user.email,
  //       roleId: data.user.role,
  //       // category: data.category,
  //       // message: data.message,
  //     };
  //     apiCall({url: '/api/v1/service/user', data: body, method: 'POST'})
  //     .then(resp => {
  //       resolve(resp.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       reject(httpCodeToErr(err.response.status));
  //     });
  //   })
  // },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
