import apiCall from '@/utils/api'
import httpCodeToErr from '@/utils/http'

export const TARIFFS_SAVE_CALCULATOR = 'TARIFFS_SAVE_CALCULATOR'
export const TARIFFS_GET_CALCULATOR = 'TARIFFS_GET_CALCULATOR'
export const GET_VK_PERCENT = 'GET_VK_PERCENT'
export const SAVE_VK_PERCENT = 'SAVE_VK_PERCENT'
export const TARIFFS_LIST_REQUEST = 'TARIFFS_LIST_REQUEST'
export const TARIFFS_SAVE_REQUEST = 'TARIFFS_SAVE_REQUEST'
export const TARIFFS_ORGS_LIST_REQUEST = 'TARIFFS_ORGS_LIST_REQUEST'
export const TARIFFS_APPLY_TO_ORGS_REQUEST = 'TARIFFS_APPLY_TO_ORGS_REQUEST'

const state = {
  status: '',
}

const getters = {}

const actions = {
  [TARIFFS_SAVE_CALCULATOR](ctx, data) {
    let body = {
      baseFormPrice: parseFloat(data.form.baseFormPrice),
      extraChargeQst: parseFloat(data.form.extraChargeQst),
      forEachNqst: parseInt(data.form.forEachNqst),
      extraChargeBlock: parseFloat(data.form.extraChargeBlock),
      extraChargeProgressBlock: parseFloat(data.form.extraChargeProgressBlock),
      screeningPrice: parseFloat(data.form.screeningPrice),
      extraChargeTargetFilter: parseFloat(data.form.extraChargeTargetFilter),
      age: 0, // TODO remove parseFloat(data.form.age),
      region: 0, // TODO remove parseFloat(data.form.region),
      education: 0, // TODO remove parseFloat(data.form.education),
      income: 0, // TODO remove parseFloat(data.form.income),
      filledBlockPrice: parseFloat(data.form.filledBlockPrice),
      completedQstPrice: parseFloat(data.form.completedQstPrice),
    }

    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/tariffs/calculator', data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [TARIFFS_GET_CALCULATOR]() {
    return new Promise((resolve, reject) => {
      apiCall({ url: `/api/v1/service/tariffs/calculator`, method: 'GET' })
        .then(rsp => {
          resolve(rsp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },
  async [GET_VK_PERCENT]() {
    try {
      const response = await apiCall({ url: '/api/v1/service/vk/percent', method: 'GET' })
      return response.data
    } catch (err) {
      console.log(err)
      throw httpCodeToErr(err.response.status)
    }
  },
  async [SAVE_VK_PERCENT](_, percent) {
    try {
      const response = await apiCall({ url: '/api/v1/service/vk/percent', method: 'POST', data: { percent } })
      return response.data
    } catch (err) {
      console.log(err)
      throw httpCodeToErr(err.response.status)
    }
  },

  [TARIFFS_LIST_REQUEST]() {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/tariffs', method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [TARIFFS_SAVE_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      let body = {
        name: data.tariff.name,
        discount: parseFloat(data.tariff.discount),
      }

      apiCall({ url: '/api/v1/service/tariffs', data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [TARIFFS_ORGS_LIST_REQUEST]() {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/v1/service/tariffs/orgs', method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [TARIFFS_APPLY_TO_ORGS_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      let body = {
        orgs: data.orgs,
        tariffId: data.tariffId,
        // finishByDate: data.finishByDate,
        finishByOrders: data.finishByOrders,
      }
      apiCall({ url: '/api/v1/service/tariffs/orgs', data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
